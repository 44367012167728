import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-univers',
  templateUrl: './univers.component.html',
  styleUrls: ['./univers.component.scss']
})
export class UniversComponent implements OnInit {

  univers: any = [];
  userFullName: string;
  constructor(
    private router: Router,
    private toastTr: ToastrService,
    private userService: UserService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    const username = this.userService.userFullName
    if (!username) {
      this.router.navigateByUrl('/')
    } else {
      this.userFullName = (username as string)
    }
    this.univers = [
      {
        name: 'Orange Money',
        image: 'wallet.svg',
        permissionName: 'VIEW_OM',
        active: true
      },
      {
        name: 'Air Time',
        image: 'airtime.svg',
        permissionName: 'VIEW_AIRTIME',
        active: false
      }
    ]


  }

  goToUnivers(index: number) {
    const item = this.univers[index]
    if (item) {
      if (item.active) {
        this.router.navigate(['/app/dashboard'])
      } else {
        this.toastTr.info('Cette fonctionnalité est indisponible pour le moment')
      }
    }

  }

  async logout(e: any) {
    e.preventDefault();
    await this.authenticationService.logout();
  }

}
