import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: 'Accueil',
    icon: 'home.svg',
    link: '/app/dashboard',
    isHome: true,
    permissionName: 'VIEW_DASHBOARD'
  },
  {
    isSeparator: true
  },
  {
    label: 'Distribution',
    isTitle: true
  },
  {
    label: 'Niveau de stock d\'UV',
    icon: 'stock.svg',
    link: '/app/uv-stock-level',
    permissionName: 'VIEW_PAGE_NIVEAU_STOCK_UV'
  },
  {
    label: 'Taux de rupture',
    icon: 'taux-rupture.svg',
    link: '/app/taux-rupture',
    permissionName: 'VIEW_PAGE_TAUX_RUPTURE'
  },
  {
    label: 'PDVs en rupture',
    icon: 'pdv-rupture.svg',
    link: '/app/pdv-rupture',
    permissionName: 'VIEW_PAGE_PDV_RUPTURE'
  },
  {
    label: 'Géolocalisation des PDVs',
    icon: 'pdv-geolocation.svg',
    link: '/app/geolocatisation-pdv',
    permissionName: 'VIEW_PAGE_ALERTE'
  },
  /*{
    label: 'Approvisionnement en UV',
    icon: 'pdv-rupture.svg',
    link: '/app/geolocatisation-pdv',
    permissionName: 'VIEW_PAGE_ALERTE'
  },
  {
    label: 'Commissions',
    icon: 'pdv-rupture.svg',
    link: '/app/geolocatisation-pdv',
    permissionName: 'VIEW_PAGE_ALERTE'
  },
  {
    label: 'Inactivités PDVs',
    icon: 'pdv-rupture.svg',
    link: '/app/geolocatisation-pdv',
    permissionName: 'VIEW_PAGE_ALERTE'
  },
  {
    label: 'Meilleurs PDVs',
    icon: 'pdv-rupture.svg',
    link: '/app/geolocatisation-pdv',
    permissionName: 'VIEW_PAGE_ALERTE'
  },
  {
    label: 'Nouveaux Agents',
    icon: 'pdv-rupture.svg',
    link: '/app/geolocatisation-pdv',
    permissionName: 'VIEW_PAGE_ALERTE'
  },*/
  // {
  //   label: 'Prévision des PDV en rupture de stock',
  //   icon: 'prevision-rupture.svg',
  //   link: '/app/prevision-pdv-rupture',
  // },
  {
    isSeparator: true
  },
  {
    label: 'Niveau MAJ',
    icon: 'maj.svg',
    link: '/app/niveau-maj',
    permissionName: 'VIEW_PAGE_NIVEAU_MAJ',
    isActive: false
  },
  {
    label: 'Réseau de distribution',
    icon: 'setting.svg',
    permissionName: 'VIEW_PAGE_RESEAU_DISTRIBUTION',
    subItems: [
      {
        label: 'Régions',
        link: '/app/reseau/zones',
        permissionName: 'VIEW_PAGE_REGION'
      },
      {
        label: 'Territoires',
        link: '/app/reseau/territoires',
        permissionName: 'VIEW_PAGE_TERRITOIRE'
      },
      {
        label: 'Localités',
        link: '/app/reseau/localites',
        permissionName: 'VIEW_PAGE_TERRITOIRE'
      },
      {
        label: 'Distributeurs',
        link: '/app/reseau/distributeurs',
        permissionName: 'VIEW_PAGE_DISTRIBUTEUR'
      },
    ]
  },
  {
    label: 'Utilisateurs',
    icon: 'setting.svg',
    permissionName: 'VIEW_PAGE_PARAMETRAGE',
    subItems: [
      {
        label: 'Utilisateurs',
        link: '/app/configuration/utilisateurs',
        permissionName: 'VIEW_PAGE_USER',
      },
      {
        label: 'Profils',
        link: '/app/configuration/profils',
        permissionName: 'VIEW_PAGE_PROFIL'
      },
      {
        label: 'Fonctionnalités',
        link: '/app/configuration/fonctionnalites',
        permissionName: 'VIEW_PAGE_PERMISSION'
      },
      {
        label: 'Historique des actions',
        link: '/app/report/action-history',
        permissionName: 'VIEW_PAGE_USER',
      },
    ]
  },
  {
    label: 'Autres',
    icon: 'setting.svg',
    subItems: [
      {
        label: 'Seuil',
        link: '/app/configuration/seuil',
        permissionName: 'VIEW_PAGE_SEUIL',
      },
      {
        label: 'Alerte',
        link: '/app/configuration/alerte',
        permissionName: 'VIEW_PAGE_ALERTE'
      }
    ]
  },
  // {
  //   label: 'Paramètre',
  //   icon: 'setting.svg',
  //   link: '/app/parametre'
  // }
];
