import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {RestClientService} from '../rest-client.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {Router} from "@angular/router";
import {CookieNameList, CookieService} from "../cookie/cookie.service";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private readonly basePath: string = 'user'
  private authenticatedUser = new Subject<any>();
  constructor(
    private restClient: RestClientService,
    private readonly cookieService: CookieService,
    private permissionsService: NgxPermissionsService,
    private router: Router
  ) { }

  login(datas: any): Observable<any> {
    const bodyDatas = {
      isAd: datas.isAd,
      "data": {
        login: datas.username,
        password: datas.password
      }
    }
    return this.restClient.post(`${this.basePath}/login`, bodyDatas)
  }

  setAuthenticatedData(datas: any) {

    //this.authenticatedUser.next(datas)
  }

  /**
   * Logout
   */
  async logout() {
    this.cookieService.removeAll()
    if (!this.cookieService.get(CookieNameList.USER_INFO)) {
      await this.router.navigate(['']);
    }
  }




}
