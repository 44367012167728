<nav class="navbar app-top-navbar">
    <a href="" class="sidebar-toggler" (click)="toggleSidebar($event)">
      <i class="feather icon-menu"></i>
    </a>
    <div class="navbar-content">
      <div class="text-welcome-user">
        <h5 class="title fw-normal">BIENVENUE <span class="text-primary text-uppercase">{{ userFullName }}</span></h5>
      </div>
      <ul class="navbar-nav">
        <li class="nav-item">
          <app-full-calendar-date-filter class="global-date-filter" [disableMinutes]="true" (dateChange)="onDateChange($event)" />
        </li>
        <li class="nav-item nav-notifications d-none" ngbDropdown>
          <a class="nav-link" ngbDropdownToggle id="notificationDropdown">
            <img src="assets/images/icons/cloche.svg" alt="notification" srcset="">
            <div class="indicator d-none">
              <div class="circle"></div>
            </div>
          </a>
          <div ngbDropdownMenu class="px-0" aria-labelledby="notificationDropdown">
            <div class="px-3 py-2 d-flex align-items-center justify-content-between border-bottom">
              <p>6 Notifications</p>
              <a href="" (click)="false" class="text-muted d-none">Clear all</a>
            </div>
            <div class="p-1">
              <a href="" (click)="false" class="dropdown-item d-flex align-items-center py-2">
                <div class="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3">
                  <i class="feather icon-gift icon-sm text-white"></i>
                </div>
                <div class="flex-grow-1 me-2">
                  <p>New Order Recieved</p>
                  <p class="tx-12 text-muted">30 min ago</p>
                </div>
              </a>
              <a href="" (click)="false" class="dropdown-item d-flex align-items-center py-2">
                <div class="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3">
                  <i class="feather icon-alert-circle icon-sm text-white"></i>
                </div>
                <div class="flex-grow-1 me-2">
                  <p>Server Limit Reached!</p>
                  <p class="tx-12 text-muted">1 hrs ago</p>
                </div>
              </a>
            </div>
            <div class="px-3 py-2 d-flex align-items-center justify-content-center border-top">
              <a href="" (click)="false">Voir tout</a>
            </div>
          </div>
        </li>
        <li class="nav-item nav-profile" ngbDropdown>
          <a class="nav-link" ngbDropdownToggle id="profileDropdown">
            <img src="assets/images/icons/user.svg" alt="user" srcset="">
          </a>
          <div ngbDropdownMenu class="px-0" aria-labelledby="profileDropdown">
            <div class="d-flex flex-column align-items-center border-bottom px-5 py-3">
              <div class="mb-3">
                <img class="wd-80 ht-80 rounded-circle" src="https://via.placeholder.com/80x80" alt="">
              </div>
              <div class="text-center">
                <p class="tx-16 fw-bolder">{{ userFullName }}</p>
                <p class="tx-12 text-muted">{{ userEmail }}</p>
              </div>
            </div>
            <ul class="list-unstyled p-1">
              <!-- <li class="dropdown-item py-2">
                <a routerLink="/general/profile" class="d-flex text-body ms-0">
                  <i class="feather icon-user me-2 icon-md"></i>
                  <span>Mon compte</span>
                </a>
              </li> -->
              <li class="dropdown-item py-2">
                <a href="" (click)="logout($event)" class="d-flex text-body ms-0">
                  <i class="feather icon-log-out me-2 icon-md"></i>
                  <span>Déconnexion</span>
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
</nav>
