import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DistributeurService } from 'src/app/services/distributeur/distributeur.service';
import { PdvService } from 'src/app/services/pdv/pdv.service';

import MetisMenu from 'metismenujs';

import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

  @ViewChild('sidebarToggler') sidebarToggler: ElementRef;

  menuItems: MenuItem[] = [];
  @ViewChild('sidebarMenu') sidebarMenu: ElementRef;

  subMenuAccordionOpen: boolean = false
  activeSubMenuAccordionOpen: number;

  currentDate = new Date();
  lastPDVStockUpdate: string = ''
  lastDistributeurStockUpdate: string = ''
  currentStockDateSubscription: Subscription

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    router: Router,
    private pdvService: PdvService,
    private distributeurService: DistributeurService,
    private toastr: ToastrService,
    private dashboardService: DashboardService
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {

        /**
         * Activating the current active item dropdown
         */
        this._activateMenuDropdown();

        /**
         * closing the sidebar
         */
        if (window.matchMedia('(max-width: 991px)').matches) {
          this.document.body.classList.remove('sidebar-open');
        }

      }
    });
  }

  ngOnInit(): void {
    //Add active property to menu list and filter non-active menu
    this.menuItems = MENU.map(item => {
      if(!item.isTitle && !item.isSeparator && !item.isHome){
        item.isActive = !('isActive' in item)
      }
      return item
    }).filter(item => item.isActive || item.isTitle || item.isSeparator || item.isHome);

    this.getLastDistributeurStockUpdate()
    this.getLastPdvStockUpdate()

    /**
     * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
     */
    const desktopMedium = window.matchMedia('(min-width:992px) and (max-width: 1199px)');
    desktopMedium.addEventListener('change', () => {
      this.iconSidebar;
    });
    this.iconSidebar(desktopMedium);

    this.currentStockDateSubscription = this.dashboardService.currentStockDate.subscribe({
      next: (currentStockDate) => {
        this.currentDate = currentStockDate.fulldate
      }
    })

    this.dashboardService.getStockLastTime()
  }

  ngOnDestroy(): void {
    this.currentStockDateSubscription.unsubscribe()
  }

  ngAfterViewInit() {
    // activate menu item

    setTimeout(() => {
      const mm = new MetisMenu(this.sidebarMenu.nativeElement);
      this._activateMenuDropdown();
    }, 500);


  }

  /**
   * Toggle sidebar on hamburger button click
   */
  toggleSidebar(e: Event) {
    this.sidebarToggler?.nativeElement.classList.toggle('active');
    this.sidebarToggler?.nativeElement.classList.toggle('not-active');
    if (window.matchMedia('(min-width: 992px)').matches) {
      e.preventDefault();
      this.document.body.classList.toggle('sidebar-folded');
    } else if (window.matchMedia('(max-width: 991px)').matches) {
      e.preventDefault();
      this.document.body.classList.toggle('sidebar-open');
    }
  }


  /**
   * Toggle settings-sidebar
   */
  toggleSettingsSidebar(e: Event) {
    e.preventDefault();
    this.document.body.classList.toggle('settings-open');
  }


  /**
   * Open sidebar when hover (in folded folded state)
   */
  operSidebarFolded() {
    if (this.document.body.classList.contains('sidebar-folded')) {
      this.document.body.classList.add("open-sidebar-folded");
    }
  }


  /**
   * Fold sidebar after mouse leave (in folded state)
   */
  closeSidebarFolded() {
    if (this.document.body.classList.contains('sidebar-folded')) {
      this.document.body.classList.remove("open-sidebar-folded");
    }
  }

  /**
   * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
   */
  iconSidebar(mq: MediaQueryList) {
    if (mq.matches) {
      this.document.body.classList.add('sidebar-folded');
    } else {
      this.document.body.classList.remove('sidebar-folded');
    }
  }


  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }


  /**
   * Reset the menus then hilight current active menu item
   */
  _activateMenuDropdown() {
    this.resetMenuItems();
    this.activateMenuItems();
  }


  /**
   * Resets the menus
   */
  resetMenuItems() {

    const links = document.getElementsByClassName('nav-link-ref');

    for (let i = 0; i < links.length; i++) {
      const menuItemEl: any = links[i];
      menuItemEl.classList.remove('mm-active');
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
        parentEl.classList.remove('mm-active');
        const parent2El = parentEl.parentElement;

        if (parent2El) {
          parent2El.classList.remove('mm-show');
        }

        const parent3El = parent2El?.parentElement;
        if (parent3El) {
          parent3El.classList.remove('mm-active');

          if (parent3El.classList.contains('side-nav-item')) {
            const firstAnchor = parent3El.querySelector('.side-nav-link-a-ref');

            if (firstAnchor) {
              firstAnchor.classList.remove('mm-active');
            }
          }

          const parent4El = parent3El.parentElement;
          if (parent4El) {
            parent4El.classList.remove('mm-show');

            const parent5El = parent4El.parentElement;
            if (parent5El) {
              parent5El.classList.remove('mm-active');
            }
          }
        }
      }
    }
  };


  /**
   * Toggles the menu items
   */
  activateMenuItems() {

    const links: any = document.getElementsByClassName('nav-link-ref');

    let menuItemEl: any = null;

    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
      if (window.location.pathname === links[i]['pathname']) {

        menuItemEl = links[i];

        break;
      }
    }

    if (menuItemEl) {
      menuItemEl.classList.add('mm-active');
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
        parentEl.classList.add('mm-active');

        const parent2El = parentEl.parentElement;
        if (parent2El) {
          parent2El.classList.add('mm-show');
        }

        const parent3El = parent2El.parentElement;
        if (parent3El) {
          parent3El.classList.add('mm-active');

          if (parent3El.classList.contains('side-nav-item')) {
            const firstAnchor = parent3El.querySelector('.side-nav-link-a-ref');

            if (firstAnchor) {
              firstAnchor.classList.add('mm-active');
            }
          }

          const parent4El = parent3El.parentElement;
          if (parent4El) {
            parent4El.classList.add('mm-show');

            const parent5El = parent4El.parentElement;
            if (parent5El) {
              parent5El.classList.add('mm-active');
            }
          }
        }
      }
    }
  };

  toggleAccordionMenu(index: number) {
    this.activeSubMenuAccordionOpen = index
  }

  private getLastDistributeurStockUpdate() {
    const getKPIRequest = this.distributeurService.getLastStockUpdate().subscribe({
      next: (response: any) => {
        if (response && response.hasOwnProperty('hasError') && !response.hasError) {
          this.lastDistributeurStockUpdate = `${response.item.date} ${response.item.heure}`
        } else {
          this.toastr.error('Oops! Nous ne parvenons pas à récupérer les dernières mises à jour', 'Information');
        }
      },
      error: (error: any) => {
        this.toastr.error('Oops! Une erreur inattendue a été rencontrée.', 'Error');
        throw new Error(error)
      },
      complete: () => {
        //this.loginInProgress = false
      }
    })
  }

  private getLastPdvStockUpdate() {
    const getKPIRequest = this.pdvService.getLastStockUpdate().subscribe({
      next: (response: any) => {
        if (response && response.hasOwnProperty('hasError') && !response.hasError) {
          this.lastPDVStockUpdate = `${response.item.date} ${response.item.heure}`
        } else {
          this.toastr.error('Oops! Nous ne parvenons pas à récupérer les dernières mises à jours', 'Information');
        }
      },
      error: (error: any) => {
        this.toastr.error('Oops! Une erreur inattendue a été rencontrée.', 'Error');
        throw new Error(error)
      },
      complete: () => {
        //this.loginInProgress = false
      }
    })

    setTimeout(() => {
      getKPIRequest.unsubscribe();
      //this.loginInProgress = false
    }, 30000);
  }

}
