import { HttpEvent, HttpEventType, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import {Observable, tap} from "rxjs";

export function loggingInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  console.log('From Logging Interceptor', req.url);
  return next(req).pipe(tap(event => {
    if (event.type === HttpEventType.Response) {
      const responseBody = event.body
      if(
        responseBody &&
        typeof responseBody === 'object' &&
        'hasError' in responseBody &&
        'status' in responseBody &&
        responseBody.hasError
      ) {
        console.error('Oups! An error occurred from HTTP Service with message:', (responseBody.status as any).message);
      }
    }
  }));
}
