import {Injectable} from '@angular/core';
import {CookieNameList, CookieService} from "../cookie/cookie.service";
import {Route, Router} from "@angular/router";
import {interval, map, tap} from "rxjs";
import {ToastrService} from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private readonly SESSION_CHECK_INTERVAL = 5000;
  constructor(
    private readonly cookieService: CookieService,
    private readonly toastService: ToastrService,
    private readonly router: Router
  ) {}

  startSessionCheck(): void {
    interval(this.SESSION_CHECK_INTERVAL).pipe(
      map(() => this.cookieService.get(CookieNameList.USER_INFO)),
    ).subscribe(userInfo => {
      if (!userInfo && this.router.url !== '/') {
        this.cookieService.removeAll() //Invalide la session actuelle
        this.router.navigate(['/']).then(() => this.toastService.info('Vous avez été déconnecté.'));
      }
    });
  }
}
