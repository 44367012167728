<nav class="sidebar">
    <div class="sidebar-header">

      <!--- Logo -->
      <div class="sidebar-brand nobleui-logo logo-light">
        <img src="assets/images/logos/om.svg" class="logo-img" alt="Logo Omer" srcset="">
        <span class="logo-text">Orange Money Analytics</span>
      </div>
      <!--- Toggler -->
      <div class="sidebar-toggler not-active d-none" #sidebarToggler (click)="toggleSidebar($event)">
        <span></span>
        <span></span>
        <span></span>
      </div>

    </div>
    <div class="sidebar-body" (mouseenter)="operSidebarFolded()" (mouseleave)="closeSidebarFolded()" [perfectScrollbar]>

      <!--- Sidemenu start -->
      <div class="last-update">
        <h5 class="title">DERNIÈRE MISE À JOUR</h5>
        <app-last-update-data></app-last-update-data>
      </div>
      <ul class="sidebar-nav metismenu" id="sidebar-menu" #sidebarMenu>
        <ng-container *ngFor="let item of menuItems; let i = index">
          <li class="nav-item nav-category" *ngIf="item.isTitle">{{ item.label }}</li>
          <li class="nav-item" routerLinkActive="active" *ngIf="item.isHome">
            <a class="nav-link nav-link-ref"
            [routerLink]="item.link"
            [attr.data-parent]="item.parentId"
            [style]="'--sidemenu-background-mask-image: url('+ 'assets/images/icons/'+item?.icon+')'"
            >
              <span class="link-title"> {{ item.label }}</span>
              <span class="badge bg-{{item.badge.variant}}" *ngIf="item.badge">{{item.badge.text}}</span>
            </a>
          </li>
          <ng-container *ngIf="!item.isTitle && !item.isSeparator && !item.isHome && item.isActive">
          <li class="nav-item"
          routerLinkActive="active"
          *ngxPermissionsOnly="item.permissionName || ''"
          [ngClass]="{'nav__with__collapse': hasItems(item)}"
          >

            <a class="nav-link"
              *ngIf="hasItems(item)" href="javascript:void(0);"
              [style]="'--sidemenu-background-mask-image: url('+ 'assets/images/icons/'+item.icon+')'"
              aria-controls="collapseExample"
              >
              <span class="link-title"> {{ item.label }}</span>
              <i class="feather icon-chevron-down"></i>
            </a>

            <a class="nav-link nav-link-ref"
            [routerLink]="item.link" *ngIf="!hasItems(item)"
            [attr.data-parent]="item.parentId"
            [style]="'--sidemenu-background-mask-image: url('+ 'assets/images/icons/'+item?.icon+')'"
            >
              <span class="link-title"> {{ item.label }}</span>
              <span class="badge bg-{{item.badge.variant}}" *ngIf="item.badge">{{item.badge.text}}</span>
            </a>



              <ul class="sidebar-nav sub-menu nav-second-level" *ngIf="hasItems(item)" [ngClass]="{ 'show': activeSubMenuAccordionOpen === i }" aria-expanded="false">
                <ng-container *ngFor="let subitem of item.subItems">
                <li class="nav-item" *ngxPermissionsOnly="subitem.permissionName || ''" [ngClass]="{'side-nav-item': hasItems(subitem)}">
                  <a class="nav-link side-nav-link-a-ref"
                  *ngIf="hasItems(subitem)" href="javascript:void(0);"
                  [style]="'--sidemenu-background-mask-image: url('+ 'assets/images/icons/'+subitem?.icon+')'"
                  >
                    <span class="link-title"> {{ subitem.label }}</span>
                    <span class="link-arrow" data-feather="chevron-down" appFeatherIcon></span>
                    <i class="link-arrow feather icon-chevron-down"></i>
                  </a>

                  <a class="nav-link nav-link-ref"
                    [routerLink]="subitem.link" *ngIf="!hasItems(subitem) && subitem.hasOwnProperty('icon') "
                    [attr.data-parent]="subitem.parentId"
                    [style]="'--sidemenu-background-mask-image: url('+ 'assets/images/icons/'+subitem?.icon+')'"
                    >
                    {{ subitem.label }}
                  </a>
                  <a class="nav-link nav-link-ref no__icon"
                    [routerLink]="subitem.link" *ngIf="!hasItems(subitem)  && !subitem.hasOwnProperty('icon')"
                    [attr.data-parent]="subitem.parentId"
                    >
                    {{ subitem.label }}
                  </a>

                  <ul class="sidebar-nav sub-menu nav-third-level" *ngIf="hasItems(subitem)" aria-expanded="false">
                    <li class="nav-item" *ngFor="let subSubitem of subitem.subItems">
                      <a class="nav-link nav-link-ref" [routerLink]="subSubitem.link" [routerLinkActive]="['active']"
                        [attr.data-parent]="subSubitem.parentId">
                        <i class="link-icon" [attr.data-feather]="subitem.icon" appFeatherIcon *ngIf="subitem.icon"></i>
                        {{ subSubitem.label }}
                      </a>
                    </li>
                  </ul>
                </li>
                </ng-container>
              </ul>
          </li>
          </ng-container>
          <hr class="separator" *ngIf="item.isSeparator" />
        </ng-container>
      </ul>

      <!--- Sidemenu end -->

    </div>
  </nav>
