import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { APP_INITIALIZER } from '@angular/core';

import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FormsModule } from '@angular/forms';
import { ErrorsComponent } from './views/errors/errors.component';

//APPLICATION MODULES IMPORTS
import { UniversModule } from './views/authenticated/pages/univers/univers.module';
import { LayoutModule } from './views/authenticated/layout/layout.module';
import { SharedModule } from './shared/shared.module';

import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { registerLocaleData } from '@angular/common';
import { withInterceptors, provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { DataTablesModule } from 'angular-datatables';
import { FonctionnaliteComponent } from './views/authenticated/pages/fonctionnalite/fonctionnalite.component';
import { ApplicationComponent } from './views/authenticated/pages/application/application.component';
import { NetworkStatusComponent } from './shared/components/network-status/network-status.component';

import { NgxPermissionsModule } from 'ngx-permissions';
import { AuthenticationModule } from './views/authentication/authentication.module';
import {loggingInterceptor} from "./interceptors/logging.interceptor";
import {httpServiceInterceptor} from "./interceptors/http-service.interceptor";
import {authenticationInterceptor} from "./interceptors/authentication.interceptor";

registerLocaleData(localeEs)
registerLocaleData(localeFr)


@NgModule({
  declarations: [
    AppComponent,
    ErrorsComponent,
    FonctionnaliteComponent,
    ApplicationComponent,
    NetworkStatusComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    UniversModule,
    AuthenticationModule,
    LayoutModule,
    SharedModule,
    NgbModule,
    DataTablesModule,
    SweetAlert2Module.forRoot(),
    NgxPermissionsModule.forRoot()
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    provideHttpClient(withInterceptors([loggingInterceptor, authenticationInterceptor,httpServiceInterceptor])),
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport())
  ]
})
export class AppModule { }
