import {HttpEvent, HttpEventType, HttpInterceptorFn, HttpResponse} from '@angular/common/http';
import {from, map, tap} from "rxjs";
import {CryptoService} from "../services/crypto/crypto.service";
import {inject} from "@angular/core";

const dataToEncrypt = 'Synelia - Projet Sécurisation';
const fakeKey = 'MTIzNDU2Nzg5MDEyMzQ1Ng==';

export const httpServiceInterceptor: HttpInterceptorFn = (req, next) => {
  console.log('From httpServiceInterceptor Interceptor', req.url);
  const cryptoService = inject(CryptoService)
  // TODO: Remove this after your test please

  const encryptedData = localStorage.getItem('helloEncryptedData')
  if(encryptedData){
    console.log('encryptedData', encryptedData);
    from(cryptoService.decryptData(fakeKey, encryptedData))
      .subscribe(response => {
        console.log('xxxx decryption response xxxxx', response);
        localStorage.setItem('helloDecryptedData', response);
      })
  }

  from(cryptoService.encryptData(fakeKey, dataToEncrypt))
    .subscribe(response => {
      console.log('xxxx encryption response xxxxx', response);
      localStorage.setItem('helloEncryptedData', response);
    })

  //End TODO
  let requestClone = req.clone()
  let requestBody = req.body
  if(req.method !== 'GET' && typeof req.body === 'object') {
    if(req.body instanceof FormData){
      const formData = new FormData();
      req.body.forEach((value: any, key: string) => {
        formData.append(key, value);
      });
      requestBody = formData
      requestClone = requestClone.clone({
        body: requestBody
      })
    } else {
      requestClone = requestClone.clone({
        body: requestBody
      })
    }

    /* return cryptoService.encryptData(fakeKey, JSON.stringify(requestBody))
      .then((encryptedBody) => {
        const encryptedReq = req.clone({
          body: encryptedBody
        });
        return next(encryptedReq).toPromise();  // Gestion asynchrone
      })
      .then((event: any) => {
        if (event.type === HttpEventType.Response) {
          return decryptResponse(event);
        }
        return event;
      })
      .catch((error) => {
        console.error('Error during encryption/decryption:', error);
        throw error;
      });*/




    return next(requestClone)
      .pipe(
        map(event => {
          if (event.type === HttpEventType.Response) {
            /* const responseClone = event.clone({
              body: cryptoService.decryptData(fakeKey, JSON.stringify(event.body))
            })*/

            // return responseClone
          }
          return event;
        })
      );
  }

  return next(requestClone)
    .pipe(
      tap((event) => {
        console.log('xxxx http service response 2 xxxxx', event)
      }),
      map(event => {
        /*if (event.type === HttpEventType.Response) {
          console.log('Response type')
          const responseClone = event.clone({
            body: cryptoService.decryptData(fakeKey, JSON.stringify(event.body))
          })

          return responseClone
        }*/
        return event;
      })
    );
  // return next(req);
};

const decryptResponse = (event: any): Promise<HttpEvent<any>> => {
  const cryptoService = inject(CryptoService)
  if (event.body) {
    return cryptoService.decryptData(fakeKey, event.body)
      .then((decryptedBody) => {
        const decryptedResponse = event.clone({
          body: decryptedBody
        });
        return decryptedResponse;
      });
  }
  return Promise.resolve(event);
}
