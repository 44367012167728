import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  private readonly algorithm = {
    name: 'AES-GCM',
    length: 256,
  };
  private readonly ivLength = 12;
  constructor() { }

  async encryptData(key: string, data: string): Promise<string> {
    const keyDecoded = atob(key);
    try {
      const encoder = new TextEncoder();
      const keyBuffer = await crypto.subtle.importKey(
        'raw',
        encoder.encode(keyDecoded),
        {
          ...this.algorithm
        },
        true,
        ['encrypt', 'decrypt']
      );
      const iv = crypto.getRandomValues(new Uint8Array(this.ivLength));
      const encodedData = encoder.encode(data);
      const encryptedData = await crypto.subtle.encrypt(
        {
          ...this.algorithm,
          iv: iv
        },
        keyBuffer,
        encodedData
      );
      const resultArray = new Uint8Array(iv.length + new Uint8Array(encryptedData).length);
      resultArray.set(iv);
      resultArray.set(new Uint8Array(encryptedData), iv.length);
      // return Buffer.from(resultArray).toString('base64');
      return btoa(String.fromCharCode(...resultArray));
    } catch (error) {
      console.error('Encryption failed:', error);
      throw error; // Rethrow the error for the caller to handle
    }
  }

  async decryptData(key: string, encryptedData: string): Promise<string> {
    const keyDecoded = atob(key);
    try {
      const encoder = new TextEncoder();
      const keyBuffer = await crypto.subtle.importKey(
        'raw',
        encoder.encode(keyDecoded),
        {
          ...this.algorithm
        },
        true,
        ['encrypt', 'decrypt']
      );      // Decryption logic
      const encryptedArray = new Uint8Array(
        atob(encryptedData)
          .split('')
          .map((char) => char.charCodeAt(0))
      );      const iv = encryptedArray.slice(0, this.ivLength);
      const ciphertext = encryptedArray.slice(this.ivLength);
      const decryptedData = await crypto.subtle.decrypt(
        {
          ...this.algorithm,
          iv: iv
        },
        keyBuffer,
        ciphertext
      );
      return new TextDecoder().decode(decryptedData);
    } catch (error) {
      console.error('Decryption failed:', error);
      throw error; // Rethrow the error for the caller to handle
    }
  }
}
