import {HttpInterceptorFn} from '@angular/common/http';
import Cookies from "js-cookie";
import {UtilitiesService} from "../services/utilities/utilities.service";
import {inject} from "@angular/core";
import {UserService} from "../services/user/user.service";
import {CookieNameList, CookieService} from "../services/cookie/cookie.service";

export const authenticationInterceptor: HttpInterceptorFn = (req, next) => {
  const sessionUser = inject(CookieService).get(CookieNameList.USER_SESSION_KEY);
  const randomKey = inject(UtilitiesService).generateRandomString(20)
  const userMatricule = inject(UserService).userMatricule
  console.log('Authentication Interceptor')
  let requestClone = req.clone()
  requestClone = requestClone.clone({
    setHeaders: {
      user: userMatricule
    }
  })
  if (sessionUser) {
    requestClone = requestClone.clone({
      setHeaders: {
        sessionUser
      }
    })
  }

  if(req.method !== 'GET' && typeof req.body === 'object') {

    if(req.body instanceof FormData){
      const formData = new FormData();
      req.body.forEach((value: any, key: string) => {
        formData.append(key, value);
      });
      formData.append('key', randomKey);
      formData.append('user', userMatricule);
      requestClone = requestClone.clone({
        body: formData
      })
    } else {
      const modifiedBody = {
        ...req.body,
        key: randomKey,
        user: userMatricule
      };
      requestClone = requestClone.clone({
        body: modifiedBody
      })
    }

    return next(requestClone);
  }
  return next(requestClone);
};
