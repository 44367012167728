import { Component, OnInit, Inject, ViewChild, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import dayjs from 'dayjs/esm';
import { DaterangepickerDirective, LocaleConfig } from 'ngx-daterangepicker-material';
import { ChosenDate, DaterangepickerComponent, DateRanges, EndDate, StartDate, TimePeriod } from 'ngx-daterangepicker-material/daterangepicker.component';
import { UserService } from 'src/app/services/user/user.service';
import { DashboardFilterDataService } from 'src/app/services/dashboard-filter-data/dashboard-filter-data.service';
import { LastUpdateDataService } from 'src/app/services/last-update-data/last-update-data.service';
import {
  FullCalendarDateFilterComponent
} from "../../../../shared/components/full-calendar-date-filter/full-calendar-date-filter.component";
import {AuthenticationService} from "../../../../services/authentication/authentication.service";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  //TODO Extract Daterangepicker into a component
  @ViewChild(DaterangepickerDirective, { static: true }) pickerDirective: DaterangepickerDirective;
  userFullName: string = "";
  userEmail: string = "";
  options = {
    autoApply: false,
    alwaysShowCalendars: false,
    showCancel: true,
    showClearButton: false,
    linkedCalendars: true,
    singleDatePicker: false,
    showWeekNumbers: false,
    showISOWeekNumbers: false,
    customRangeDirection: true,
    lockStartDate: false,
    closeOnAutoApply: true,
    timePicker: true
  };
  ranges: DateRanges = {
    ['Aujourd\'hui']: [dayjs(), dayjs()],
    ['Hier']: [dayjs().subtract(1, 'days'), dayjs().subtract(1, 'days')],
    ['Cette semaine (lundi dernier)']: [dayjs().subtract(6, 'days'), dayjs()],
    ['7 derniers jours']: [dayjs().subtract(6, 'days'), dayjs()],
    ['Ce mois-ci']: [dayjs().startOf('month'), dayjs().endOf('month')],
    ['30 derniers jours']: [dayjs().subtract(29, 'days'), dayjs()],
    ['90 derniers jours']: [dayjs().subtract(3, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    ['Dernière mise à jour']: [dayjs().subtract(3, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
  };
  invalidDates: dayjs.Dayjs[] = [];
  inlineDate: ChosenDate;
  minDate: dayjs.Dayjs = dayjs().subtract(90, 'days');
  maxDate: dayjs.Dayjs = dayjs().add(3, 'month');
  picker: DaterangepickerComponent;
  locale: LocaleConfig = {
    format: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
    displayFormat: 'DD/MM/YYYY HH:mm',
    separator: ' au ',
    cancelLabel: 'Annuler',
    applyLabel: 'Filtrer'
  };

  selected: any = { start: null, end: null };
  filterStartDate: string = ''
  filterEndDate: string = ''
  isLastDate: boolean = true
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private userService: UserService,
    private dashboardFilterService: DashboardFilterDataService,
    private lastUpdateDataService: LastUpdateDataService,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    const username = this.userService.userFullName
    // const authChannel = new BroadcastChannel('auth');
    // authChannel.postMessage({ cmd: 'logout', user: 'Eric Bidelman' });
    // authChannel.onmessage = (e) => {
    //   console.log('event data', e)
    //   if (e.data.cmd === 'logout') {
    //     console.log('event data Log')
    //   }
    // };
    if (!username) {
      this.router.navigateByUrl('/')
    } else {
      this.userFullName = (username as string)
      this.userEmail = this.userService.email ? this.userService.email.toString() : ""
    }
    if(this.pickerDirective){
      this.picker = this.pickerDirective.picker;
      //Observer pour la date de dernière mise à jour
      this.lastUpdateDataService.lastUpdateData.subscribe((data: { startDate: string, endDate: string, startHour: string, endHour: string }) => {
        this.isLastDate = true
        this.picker.startDate = dayjs(`${data.startDate} ${data.startHour}`, 'DD/MM/YYYY HH:mm')
        this.picker.endDate = dayjs(`${data.endDate} ${data.endHour}`, 'DD/MM/YYYY HH:mm')
      });
    }

  }

  click(): void { }

  clear(): void {
    this.pickerDirective.clear();
  }
  open(e: MouseEvent): void {
    this.pickerDirective.open(e);
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e: Event) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  async logout(e: Event) {
    e.preventDefault();
    await this.authenticationService.logout();
  }

  onDateChange(payload: {dateParam: any, isLastDate: boolean}) {
    console.log('onDateChange', payload);
    this.dashboardFilterService.filterData({ startDate: payload.dateParam.start.format('DD/MM/YYYY'), endDate: payload.dateParam.end.format('DD/MM/YYYY'), startHour: payload.dateParam.start.format('HH:mm:ss'), endHour: payload.dateParam.end.format('HH:mm:ss'), isLastDate: payload.isLastDate })
  }
}
